import "core-js/stable";
import "regenerator-runtime/runtime";
import ResponsiveAutoHeight from "responsive-auto-height";
// calling method from utilities
import { initScrollReveal, initScrollRevealDelay } from "./scroll-reveal";
import { getAhrefParent } from "./utilities";
import Glide from "@glidejs/glide";
import GLightbox from "glightbox";
import Sticky from "sticky-js";


/*==========================================
 Variables
===========================================*/

const htmlElement = document.querySelector("html");
const bodyElement = document.body;
const headerElement = document.querySelector("header#o-header");
const siteOverlayElement = document.querySelector("#a-site-overlay");
const preLoaderOverlayElement = document.querySelector("#a-pre-load-overlay");

/*==========================================
 Click Events Listeners
===========================================*/
//This is METHOD what adds click event to any element
bodyElement.addEventListener("click", evt => {
    //making specific click event to a element
    const clickedElement = evt.target;
    if (clickedElement) {
        const aHrefParent = getAhrefParent(clickedElement, 0);

        if (
            aHrefParent &&
            aHrefParent.getAttribute("href") &&
            aHrefParent.getAttribute("href").startsWith("#")
        ) {
            evt.preventDefault();
            const scrollElementId = aHrefParent.getAttribute("href");
            const scrollElement = document.querySelector(scrollElementId);
            scrollToElement(scrollElement);
        }
    }
});  
function viewportCheck() {
    if (isInViewport(statsContainer) && !isInViewportCheck) {
        countUpFunc();
        isInViewportCheck = true;
    }
}

let myElem = document.querySelector('.m-content-block');
   
/*==========================================
 RTE IFRAMES WRAPPED IN DIV 
===========================================*/
const contentIFrames = [].slice.call(document.querySelectorAll(".m-content-block iframe"));

//Checks Content Iframes are on the page
if (contentIFrames && contentIFrames.length > 0){
    //Loops over each iframe
    contentIFrames.forEach(iframeElement => {
        //Gets the parent of the iframe
        const parentNode = iframeElement.parentNode;
        //Create div
        const iFrameWrapper = document.createElement('div');
        //Give new div a class name
        //iFrameWrapper.className = "m-iframe";
        iFrameWrapper.className = "m-iframe aspect-ratio-16x9";
        //Add the iframe to the new div
        iFrameWrapper.appendChild(iframeElement);
        
        //If the iframe element has a sibling, if its not the last element in its container
        if (iframeElement.nextSibling){
            //insert the new iFrameWrapper before the iframe element, so that its in the right place
            parentNode.insertBefore(iFrameWrapper, iframeElement);
        }
        //add to the end of the container
        else {
            parentNode.appendChild(iFrameWrapper);
        }
});
}

/*==========================================
 Init plugins
===========================================*/

/*==========================================
Glide
===========================================*/
const sliderElements = [].slice.call(document.querySelectorAll(".glide.m-slider-block"));
//const sliderElements = [...document.querySelectorAll(".glide.m-slider-block")];

const initSlider = sliderElement => {
    const sliderId = sliderElement.id;

    let slider = new Glide(`#${sliderId}`, {
        type: "carousel"
    });

    slider.mount();
};

if (sliderElements && sliderElements.length) {
    sliderElements.forEach(sliderElement => {
        initSlider(sliderElement);
    });
}

/*==========================================
   GLightbox
 ===========================================*/

const lightbox = GLightbox({
    selector: ".js-lightbox-play"
});

/*==========================================
Sticky
===========================================*/

var sticky = new Sticky(".js-sticky");

/*==========================================
Pre Loader
===========================================*/

const ready = fn => {
    if (document.readyState != "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
};

const hidePreloader = () => {
    preLoaderOverlayElement.classList.add("v-hidden");
    htmlElement.style.fontSize = null;

    setTimeout(() => {
        initScrollReveal();
        setTimeout(() => {
            preLoaderOverlayElement.classList.add("display-none");
        }, 200);
    }, 400);
};

setTimeout(() => {
    ready(hidePreloader);
}, 300);

/*==========================================
Equal Height
===========================================*/

const runMatchHeight = () => {

    if (document.querySelector(".m-news-reports-block")) {
        new ResponsiveAutoHeight(".m-news-reports-block h5");
        new ResponsiveAutoHeight(".m-news-reports-block .a-date-type");
        new ResponsiveAutoHeight(".m-news-reports-block p");

    }
};

runMatchHeight();

/*==========================================
Scroll to Anchor
===========================================*/

const scrollToElement = element => {
    console.log(element);
    element.scrollIntoView({
        behavior: "smooth"
    });
};
