import ScrollReveal from "scrollreveal";

/*==========================================
  Scroll Reveal 
===========================================*/

const scrollRevealAfterCallback = el => {
    el.classList.add("v-sr-is-visible");
};

const scrollRevealBeforeCallback = el => {
    el.classList.add("v-sr-before-visible");
};

export const srOptions = {
    distance: "24px",
    viewOffset: {
        top: 24
    },
    duration: 1000,
    beforeReveal: scrollRevealBeforeCallback,
    afterReveal: scrollRevealAfterCallback
};

export const srDelayOptions = {
    duration: 1000,
    interval: 100,
    beforeReveal: scrollRevealBeforeCallback,
    afterReveal: scrollRevealAfterCallback
}; 

export const srHomeOptions = { 
    origin : 'left',
    delay: 200,
    opacity: 0,
    distance : '200px',
    easing   : 'ease-in-out',
    duration: 700,
    interval: 100, 
    beforeReveal: scrollRevealBeforeCallback,
    afterReveal: scrollRevealAfterCallback
};

export const initScrollReveal = () => {
    const scrollRevealSelectors = "";

    ScrollReveal().reveal(scrollRevealSelectors, srOptions);

    if (document.querySelector(".v-scroll-reveal")) {
        ScrollReveal().reveal(".v-scroll-reveal", srOptions);
    }

    const scrollRevealSelectorsDelay = "";

    ScrollReveal().reveal(scrollRevealSelectorsDelay, srDelayOptions);

    if (document.querySelector(".v-scroll-reveal-delay")) {
        ScrollReveal().reveal(".v-scroll-reveal-delay", srDelayOptions);
    } 

    
    const scrollRevealHomeOption= "";

    ScrollReveal().reveal(scrollRevealHomeOption, srHomeOptions);

    if (document.querySelector(".v-scroll-home")) {
        ScrollReveal().reveal(".v-scroll-home", srHomeOptions);
    }
};
